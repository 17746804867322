@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		margin: 0;
		font-family: 'Open Sans', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow-y: scroll;
		@apply bg-gradient-to-r from-sky-700 to-blue-500;
	}

	h1 {
		@apply text-4xl font-light;
	}

	p {
		@apply text-sm leading-6;
	}

	.btn {
		@apply flex items-center justify-center transition bg-slate-800 hover:bg-sky-700 disabled:hover:bg-slate-800 disabled:opacity-50 p-4 rounded-xl text-white text-sm text-center font-bold tracking-wider uppercase cursor-pointer disabled:cursor-not-allowed;
	}

	.btn.alt {
		@apply border-slate-800 hover:border-sky-700 border-2 text-slate-800 bg-transparent hover:bg-sky-700 hover:text-white border-collapse;
	}

	.btn.light {
		@apply flex items-center justify-center transition bg-sky-700 hover:bg-slate-800 disabled:hover:bg-slate-800 disabled:opacity-50 p-4 rounded-xl text-white text-sm text-center font-bold tracking-wider uppercase cursor-pointer disabled:cursor-not-allowed;
	}

	.btn.sm {
		@apply px-4 py-2 text-xs;
	}

	.generic-input {
		@apply text-sm text-center font-semibold text-slate-800 border py-3 px-4 rounded-xl;
	}

	.generic-input.left {
		@apply text-sm text-left font-semibold text-slate-800 border py-3 px-4 rounded-xl;
	}

	.generic-input-error-message {
		@apply bg-slate-50 border-b border-l border-r rounded-b-xl text-xs font-semibold text-slate-800 tracking-wide pb-2 pt-4 py-1 text-center;
	}

	.generic-error-message {
		@apply bg-black bg-opacity-25 shadow rounded-xl text-xs font-semibold tracking-wide text-white text-center px-4 py-2;
	}

	.card {
		@apply bg-white rounded-3xl shadow-xl;
	}

	.card.glass {
		@apply bg-opacity-20;
	}

	.card.glass.top {
		@apply rounded-b-none;
	}

	.card.glass.btm {
		@apply rounded-t-none;
	}
}
